import React from "react"
import styled from "styled-components"
import Gif from "../images/width.gif"
import Resume from "../images/Resume.pdf"

const BuyScreen = () => {
  return (
    <>
      <Container data-scroll-container>
        <StyledImage src={Gif} />
      </Container>
      <Container>
        <GetReal>Get A Real Screen</GetReal>
        <LinksContainer>
          <Link href={Resume}>
            Resume <ArrowContainer>→</ArrowContainer>
          </Link>
          <Link
            style={{ color: "white" }}
            href={"https://www.linkedin.com/in/mohammad-baqer"}
          >
            LinkedIn <ArrowContainer>→</ArrowContainer>
          </Link>
          <Link style={{ color: "white" }} href={"https://github.com/mtbaqer"}>
            GitHub <ArrowContainer>→</ArrowContainer>
          </Link>
        </LinksContainer>
      </Container>
      <Title>Mobile Coming Soon</Title>
      <RTitle>
        <a style={{ color: "white" }} href="https://www.amazon.com/s?k=monitor">
          Buy{" "}
          <span
            style={{
              color: "#6ed8ed",
              marginLeft: "-1px",
              fontFamily: "Arial",
            }}
          >
            →
          </span>
        </a>
      </RTitle>
    </>
  )
}

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 6vw;
  color: white;
  padding: 5vw;
  text-align: center;
  position: absolute;
  top: 20vh;
  width: 100vw;
  font-family: Poppins;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
`

const StyledImage = styled.img`
  width: 100vw;
  margin: 0;
  padding: 0 5vw;
`

const GetReal = styled.h2`
  font-size: 11vw;
  font-family: "Playfair Display";
  font-weight: 900;
  color: white;
  padding: 5vw;
  text-align: center;
`

const Title = styled.p`
  font-size: 5vw;
  color: white;
  position: absolute;
  top: 10vh;
  width: 100vw;
  text-align: center;
`

const RTitle = styled.p`
  font-size: 6vw;
  color: white;
  text-decoration: underline;
  position: absolute;
  width: 100vw;
  bottom: 10vh;
  text-align: center;
`

const ArrowContainer = styled.span`
  color: #6ed8ed;
  margin-left: -1px;
  font-family: Arial;
`

const Link = styled.a`
  font-size: 5vw;
  color: white;
  text-align: center;
`

export default BuyScreen
