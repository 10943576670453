import React, { useEffect, useState } from "react"
import ActParticlesBG from "./ActParticlesBG"
import ScrollBackground from "./ScrollBackground"

const ParticlesBG = ({ scroll, black }) => {
  const [shade, setShade] = useState(1)

  useEffect(() => {
    if (scroll) {
      scroll.on("scroll", arg => {
        const newHeight = window.innerHeight / 2
        const newShade = 1 - (0.4 / newHeight) * arg.scroll.y
        const maxShade = Math.max(newShade, 0.4)
        setShade(maxShade)
      })
    }
  }, [scroll])

  return (
    <>
      <ScrollBackground scroll={scroll} black={black} />
      <div style={{ opacity: shade }}>
        <ActParticlesBG />
      </div>
    </>
  )
}

export default ParticlesBG
