import React, { useEffect, useState, useContext } from "react"

const ScrollBackground = ({ scroll, black }) => {
  const [shade, setShade] = useState(black ? 0 : 255)

  useEffect(() => {
    if (scroll) {
      scroll.on("scroll", arg => {
        const newHeight = window.innerHeight / 2
        const newShade = Math.round(255 - (255 / newHeight) * arg.scroll.y)
        setShade(newShade)
      })
    }
  }, [scroll])

  return (
    <div
      style={{
        height: "110vh",
        width: "100vw",
        position: "fixed",
        backgroundColor: `rgba(${shade}, ${shade}, ${shade})`,
      }}
    />
  )
}

export default ScrollBackground
