import React, { useEffect, useState } from "react"
import Particles from "react-particles-js"
import styled from "styled-components"
import ColorElement6 from "../images/color element-06.png"
import ColorElement19 from "../images/color element-19.png"
import ColorElement53 from "../images/color element-53.png"
import ColorElement21 from "../images/color element-21.png"
import ColorElement28 from "../images/color element-28.png"
import ColorElement32 from "../images/color element-32.png"
import ColorElement33 from "../images/color element-33.png"
import ColorElement34 from "../images/color element-34.png"

const ActParticlesBG = () => {
  return (
    <StyledParticlesBG
      height="110vh"
      width="100vw"
      params={{
        particles: {
          number: {
            value: 5,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          line_linked: {
            enable: false,
          },
          move: {
            speed: 2,
            out_mode: "out",
          },
          shape: {
            type: ["images"],
            images: [
              {
                src: ColorElement21,
                height: 784,
                width: 2431,
              },
              {
                src: ColorElement28,
                height: 2423,
                width: 3010,
              },
              {
                src: ColorElement32,
                height: 3697,
                width: 3923,
              },
              {
                src: ColorElement33,
                height: 4134,
                width: 1813,
              },
              {
                src: ColorElement34,
                height: 7948,
                width: 9496,
              },
              {
                src: ColorElement6,
                height: 4926,
                width: 4911,
              },
              {
                src: ColorElement19,
                height: 5112,
                width: 5420,
              },
              {
                src: ColorElement53,
                height: 1886,
                width: 1889,
              },
            ],
          },
          size: {
            value: 30,
          },
        },
        retina_detect: true,
      }}
    />
  )
}

const StyledParticlesBG = styled(Particles)`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  padding: 0;
  overflow: hidden;
  position: fixed;
  margin: 0;
  opacity: 0;
  animation: fadeIn 0.5s ease-in 1.5s forwards;
`

export default ActParticlesBG
